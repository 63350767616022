@charset 'UTF-8';

// 1. Configuration and helpers
@import 'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

//================== Filter Box ==================//

.filter-box {
  @include set-flex(row, space-between, center);
  padding: .8rem 0;
  border-top: 1px solid $color-border-03;
  border-bottom: 1px solid $color-border-03;

  .left-side {
    @include set-flex(row, flex-start, center);
  }

  .right-side {
    @include set-flex(row, flex-end, center);

    .tab-group {
      @include set-flex(row, center, center);
      margin-left: 2rem;

      .tab {
        width: 3.6rem;
        text-align: center;

        &.grid {
          a {
            border-top-left-radius: .6rem;
            border-bottom-left-radius: .6rem;
            border-right: none;
            background: asset-image('ic_apps.svg') center no-repeat;
          }
          &.active {
            a {
              background: #354056 asset-image('ic_apps_on.svg') center no-repeat;
            }
          }
        }

        &.list {
          a {
            border-top-right-radius: .6rem;
            border-bottom-right-radius: .6rem;
            border-left: none;
            background: asset-image('ic_category.svg') center no-repeat;
          }
          &.active {
            a {
              background: #354056 asset-image('ic_category_on.svg') center no-repeat;
            }
          }
        }

        a {
          display: block;
          width: 100%;
          height: 3.4rem;
          border: 1px solid $color-border-03;

        }
      }
    }

    .btn-info {
      height: 3.4rem;
      // margin-right: .8rem;
      // border-radius: 50%;
      border: 0;
      background: asset-image('ic_info_fill2.svg') no-repeat center / 2.8rem;
      &:hover {
        & + .tooltip {
          display:block;
        }
      }

      &:focus {
        & + .tooltip {
          display:block;
        }
      }
    }

    .btn-download-excel {
      height: 3.4rem;
      margin-left: 2rem;
      background: asset-image('ic_download.svg') center no-repeat;
    }

    // .tab-group

    .btn-filter {
      @include set-font(1.4rem, 500, $color-dark-gray, 110%);
      position: relative;
      min-width: 1rem;
      height: 3.4rem;
      margin-left: 2rem;
      text-align: center;
      background-color: $color-white;
      padding: 0 2.8rem 0 1rem;
      
      &:hover{
        background-color: #f5f5f5;
        text-decoration: underline;
      }
      
      &:active {
        background-color:#cccccc;
        line-height: 2.2rem;
      }

      &.active {
        &:active {
          &:after {
            top: 3px;
            right: 13px;
          }
        }
        &:after {
          content:'';
          position: absolute;
          top: 13px;
          right: 13px;
          display: inline-block;
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 100%;
          background-color: $color-primary;
        }
      }

      &:before {
        content: '';
        display: inline-block;
        position: relative;
        top: -0.2rem;
        width: 1.3rem;
        height: 1.2rem;
        margin-right: .7rem;
        background: asset-image('ic_filter2.svg') center no-repeat;
        vertical-align: middle;
      }
    }
  }

  // .right-side

  .filter-options {
    visibility: hidden;
    position: fixed;
    top: 0rem;
    right: -38rem;
    bottom: 0;
    z-index: 100;
    // width:100%;
    background-color: $color-filter-gray;
    transition: right .25s;

    &.show {
      visibility: visible;
    }

    &.expand {
      width: 38rem;
      right: 0;
    }

    .filter-options-top {
      @include set-flex(row, space-between, center);
      height: 7.8rem;
      padding: 0 1.7rem 0 2.4rem;
      background-color: $color-filter-gray;
      transition: width .25s;
      transition-delay: 2.2s;

      h2 {
        @include set-font(2rem, 700, $color-white, normal);

        &.title-pc {
          display:block;
        }

        &.title-mobile {
          display:none;
        }
      }

      .btn-close {
        width: 4rem;
        height: 4rem;
        background: asset-image('menu_icon_x_n.svg') center no-repeat;
      }
    }
    // .filter-options-top

    .search-reflow-box {
      display:none;
      position:relative;
      z-index: 3;

      input[type=text] {
        height:5.4rem;
        padding: 0 7.4rem 0 2rem;
        border-bottom:none;
        background-color: $color-white;
      }

      .btn-search {
        overflow:hidden;
        position:absolute;
        top:0;
        right:0;
        z-index: 2;
        width:5.4rem;
        height:5.4rem;
        border-radius:0;
        border:none;
        background: $color-white asset-image('icon_search.svg') center no-repeat;
        text-indent:-9999px;
      }
    }

    .button-box {
      padding: 0;

      &.apply {
        position: fixed;
        bottom: 0;
        z-index:2;
        @include set-flex(row, space-between, center);
        width: 37.6rem;
        height: 8rem;
        padding: 1.6rem;
        background-color: $color-filter-gray;

        .btn + .btn {
          margin-left:1rem;
        }
      }

      .btn {
        @include set-font(1.6rem, 400, $color-white, normal);
        width: calc(50% - .5rem);
        border-width: 1px;
        height: 4rem;

        &:active {
          border-width: 3px;
        }

        &.btn-model-selection {
          font-size:1.3rem;
        }
      }

      h3 {
        @include set-font(1.6rem, 400, $color-white, normal);
        display: block;
        width: 100%;
        margin-bottom: 1rem;
      }

      &.flex-none {
        display: block !important;

        .btn {
          width: 100%;
        }
      }
    }

    .filter-options-middle {
      overflow: hidden;
      overflow-y: auto;
      position: absolute;
      top: 7.8rem;
      right: 0;
      bottom: 0;
      width: 100%;
      &::-webkit-scrollbar-track {
        background-color: #777777; //2nd 접근성 수정
      }
      &::-webkit-scrollbar-thumb {
        background-color: #d4d4d4; //2nd 접근성 수정
      }

      &::-webkit-scrollbar-thumb:hover,
      &::-webkit-scrollbar-thumb:active {
        background-color: $color-white; //2nd 접근성 수정
      }

      .option-box {
        width: 100%;
        margin-bottom: 8rem;
        padding: 2.4rem;

        .field {
          margin-bottom: 3.5rem;

          .field-label {
            @include set-font(1.6rem, 700, $color-white, normal);
            margin-bottom: 1rem;
          }
          .field-form {
            .field-box {
              .btn-secondary {
                width: calc(100% - 1.2rem);
                background-color: transparent;
                &:hover {
                  background-color: rgba(0,0,0,0.1) !important;
                }
              }
            }
          }

          label {
            color: $color-white;

            &.field-label {
              margin-bottom: 1.5rem;
            }
          }

          &.field-type-checkbox {
            .checkbox {
              margin-bottom: 0;
              padding: 1rem 0;

              label {
                padding-left: 2.7rem;
                @include set-font(1.4rem, 400, $color-white, normal);
              }

              input[type="checkbox"]+label:before {
                background: none;
                border-radius: .4rem;
                background-color: $color-filter-gray;
                border: 2px solid $color-border-02;
              }

              input[type="checkbox"]:checked + label:after {
                border-radius: .4rem;
                border: 2px solid #2F929F;
                background: #2F929F asset-image('ic_check-w.svg') no-repeat center/1.3rem;
              }  

              .btn-open-checkbox {
                height: 1rem;
                background: asset-image('header_btn_drop_down_light.svg') no-repeat center/1.3rem;
                vertical-align: top;
                & + .field-box {
                  display: none;
                  margin-left: 2rem;
                }
                &.expand {
                  transform: rotate(180deg);
                  & + .field-box {
                    display: block;
                  } 
                }
              }
            }
          }
        }
      }
      // .option-box
    }

    // .filter-options-middle
  }

  // .filter options
}
// .filter-box

@media screen and (max-width: 640px) {
  .filter-box {
    border-top: none;
    .filter-options {
      .filter-options-top {
        .title-pc {
          display:none;
        }

        .title-mobile {
          display:block;
        }
      }
      .search-reflow-box {
        display: block;
      }

      &.expand {
        width: 100%;

        .filter-options-middle {
          .option-box {
            padding:2rem;
            padding-top:7.9rem;

            .field.field-type-checkbox {
              .checkbox {
                width:100%;
              }
            }
          }
        }
      }
    }

    .right-side {
      width: 100%;
      align-items: flex-end;
      // flex-direction: column-reverse;
      flex-direction: column;

      .btn-filter {
        margin-left: auto;
        width: 100%;
      }
      .tab-group {
        margin-top: 1.5rem;
      }
    }
  }
}